<template>
  <div>
    <v-row>
      <v-col cols="12" md="3">
        <v-autocomplete
          dense
          outlined
          hide-details
          v-model="idCustomer"
          :items="customerOptions"
          label="Khách hàng"
          placeholder="Khách hàng"
          class="c-input-small"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          dense
          outlined
          hide-details
          v-model="sku"
          label="SKU | Barcode"
          placeholder="SKU | Barcode"
          class="c-input-small"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <input-number
          dense
          outlined
          hide-details
          v-model="quantity"
          label="Số lượng"
          placeholder="Số lượng"
          class="c-input-small"
        ></input-number>
      </v-col>
      <v-col cols="12" md="3">
        <v-btn
          color="success"
          block
          @click="generateUid"
          :disabled="!idCustomer || !sku || !quantity"
        >
          RUN
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { write, utils } from "xlsx";
import { saveAs } from "file-saver";

export default {
  name: "GenerateUid",
  data: () => ({
    customers: [],
    isLoading: false,
    sku: null,
    idCustomer: null,
    quantity: null,
  }),
  computed: {
    customerOptions() {
      return [...this.customers].map((item) => ({
        text: item.company_name,
        value: item.id,
      }));
    },
  },
  mounted() {
    this.getCustomers();
  },
  methods: {
    async getCustomers() {
      const { data } = await httpClient.post(
        "/customer/v1/get-all-has-contract",
        { is_active: true }
      );
      this.customers = [...data];
    },
    async generateUid() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }

      this.isLoading = true;
      try {
        const { data } = await httpClient.post("/dc/v1/generate-uid", {
          id_customer: this.idCustomer,
          sku: this.sku,
          quantity: this.quantity,
        });
        this.isLoading = false;
        this.$vToastify.success("Chờ tí nhé. Sắp có File rồi");
        this.writeFile(data);
      } catch (e) {
        console.log(e);
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },

    s2ab(s) {
      let buf = new ArrayBuffer(s.length);
      let view = new Uint8Array(buf);
      for (let i = 0; i != s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    },

    writeFile(data) {
      const aoa = [];
      for (let i = 0; i < data.length; ++i) {
        const row = [];
        row.push(data[i]);
        aoa.push(row);
      }

      let wscols = [];
      let ws = utils.aoa_to_sheet(aoa);
      ws["!cols"] = wscols;
      let wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Sheet1");

      const wbout = write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "binary",
      });

      saveAs(
        new Blob([this.s2ab(wbout)], {
          type: "text/plain;charset=utf-8",
        }),
        `${this.sku}_x${this.quantity}.xlsx`
      );
    },
  },
};
</script>
